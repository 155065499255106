<template>
  <div>
    <v-app-bar
      color="var(--nav)"
      fixed
      flat
      app
    >
      <v-scroll-y-reverse-transition>
        <v-toolbar-title v-if="
          $parent.$children[0].$data.mini !== null && $parent.$children[0].$data.mini
        " class="ml-1">
          <v-img
            src="@/assets/eider-logo.png"
            width="150"
            height="150"
            contain
          />
        </v-toolbar-title>
      </v-scroll-y-reverse-transition>
      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-4 d-flex">
        <v-icon class="mr-2">mdi-account-circle</v-icon>
        Bienvenido, {{ this.$store.getters.user.username }}
      </v-toolbar-title>
      <v-tooltip
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="gray"
            @click="logoutG4100();"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('logout') }}</span>
      </v-tooltip>
    </v-app-bar>
  </div>
</template>

<script>
import helperG4100 from '../../mixins/helperG4100';

export default {
  name: 'navc',
  mixins: [helperG4100],
};
</script>
