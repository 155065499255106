<template>
  <eider-data-table
    :headers="headers"
    :title="$t('users.users')"
    endpoint="users"
    ref-form="usersForm"
    :delete-endpoint="deleteEndpoint"
  />
</template>

<script>
import eiderDataTable from '@/components/layout/datatable/eider-data-table.vue';

export default {
  components: {
    eiderDataTable,
  },
  name: 'users',
  data: () => ({
    deleteEndpoint: 'user',
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      { text: 'Nombre', value: 'name' },
      { text: 'Nombre de Usuario', value: 'username' },
      { text: 'Tipo', value: 'type' },
      { text: 'Emails', value: 'emails_notify' },
      { text: 'Persona', value: 'personal_id' },
      { text: 'IDS Relacionados', value: 'relation_id' },
      { text: 'Conexión', value: 'connection.name', sortable: false },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
  }),
};
</script>
