<template>
  <base-eider-form
    :addTitle="addTitle"
    :editTitle="editTitle"
    endpoint="user"
    :editedItem="editedItem"
    :set-params="setParams"
    max-width="800px"
  >
    <template v-slot:fields>
      <v-row
        justify="space-between"
      >
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            outlined
            color="var(--green)"
            v-model="editedItem.name"
            label="Nombre"
            required
            :rules="nameRules"
          ></v-text-field>
          <v-text-field
            outlined
            color="var(--green)"
            v-model="editedItem.username"
            label="Nombre de usuario"
            required
            :rules="userRules"
          ></v-text-field>
          <v-text-field
            outlined
            color="var(--green)"
            v-model="editedItem.emails_notify"
            label="Emails"
            required
            :rules="emailsRules"
          ></v-text-field>
          <v-text-field
            outlined
            color="var(--green)"
            v-model="editedItem.password"
            label="Contraseña"
            required
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
            :type="showPass ? 'text' : 'password'"
            :rules="edition ? [] : passwordRules"
          >
            <template v-slot:append-outer>
              <v-tooltip
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="top: -6px"
                    v-bind="attrs"
                    v-on="on"
                    @click="editedItem.password = $generatePassword(8)"
                  >
                    <v-icon>mdi-lock-reset</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('generate-password') }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            :items="[
          { text: 'Administrador', value: 0 },
          { text: 'Usuario', value: 5 },
         ]"
            color="var(--green)"
            item-color="var(--green)"
            item-class="var(--green)"
            outlined
            v-model="editedItem.type"
            :placeholder="$t('type')"
            :rules="edition ? [] : typeRules"
          >
          </v-select>
          <v-text-field
            outlined
            color="var(--green)"
            v-model="editedItem.personal_id"
            :label="$t('person')"
            type="number"
            :rules="parseInt(editedItem.personal_id) ? personalIDRules : []"
          ></v-text-field>
          <v-select
            outlined
            color="var(--green)"
            item-color="var(--green)"
            item-class="var(--green)"
            v-model="editedItem.connection_id"
            :label="$t('connections.literal')"
            :items="connections"
            required
            :rules="connectionsRules"
            item-text="name"
            item-value="id"
          ></v-select>
          <v-textarea
            outlined
            :rows="3"
            color="var(--green)"
            v-model="editedItem.relation_id"
            label="IDS relacionados"
            :rules="relationIdRules"
          ></v-textarea>
        </v-col>
      </v-row>
    </template>
  </base-eider-form>
</template>

<script>
import baseEiderForm from '@/components/layout/dialog/base-eider-form.vue';
import verificationRules from '@/mixins/verificationRules';

export default {
  name: 'users-eider-form',
  props: {
    addTitle: String,
    editTitle: String,
  },
  data: () => ({
    showPass: false,
    connections: [],
    edition: false,
    editedItem: {
      id: null,
      name: null,
      username: null,
      password: '',
      emails_notify: '',
      type: null,
      personal_id: null,
      connection_id: null,
      relation_id: null,
    },
  }),
  async created() {
    await this.getConnections();
  },
  methods: {
    async getConnections() {
      const { data } = await this.$http.post('/connections', {
        items_per_page: 25,
      });
      this.connections = data.data;
    },
    show(item = null) {
      this.edition = item !== null;
      this.$children[0].show(item);
    },
    setParams(item) {
      this.editedItem.name = item.name;
      this.editedItem.username = item.username;
      this.editedItem.password = item.password;
      this.editedItem.emails_notify = item.emails_notify;
      this.editedItem.personal_id = item.personal_id;
      this.editedItem.type = item.type;
      this.editedItem.connection_id = item.connection_id;
      this.editedItem.relation_id = item.relation_id;
    },
  },
  components: {
    baseEiderForm,
  },
  mixins: [verificationRules],
};
</script>
