<template>
  <v-progress-linear
    v-show="loading"
    slot="progress"
    color="var(--green)"
    indeterminate
    style="width: 100% !important;"
  ></v-progress-linear>
</template>

<script>
export default {
  name: 'progress-linear',
  props: {
    loading: Boolean,
  },
};
</script>
