<template>
  <config/>
</template>

<script>
import config from '@/components/admin/config.vue';

export default {
  components: {
    config,
  },
};
</script>
