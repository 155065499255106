<template>
  <eider-data-table
    :headers="headers"
    :title="$t('configs.configs')"
    endpoint="configs"
    ref-form="configsForm"
    :delete-endpoint="deleteEndpoint"
  />
</template>

<script>
import eiderDataTable from '@/components/layout/datatable/eider-data-table.vue';

export default {
  components: {
    eiderDataTable,
  },
  name: 'configs',
  data: () => ({
    deleteEndpoint: 'config',
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      { text: 'Clave', value: 'key' },
      { text: 'Valor', value: 'value' },
      { text: 'Activo', value: 'is_active' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
  }),
};
</script>
