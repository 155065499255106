<template>
  <base-eider-form
    :addTitle="addTitle"
    :editTitle="editTitle"
    endpoint="config"
    :editedItem="editedItem"
    :set-params="setParams"
  >
    <template v-slot:fields>
      <v-text-field
        outlined
        color="var(--green)"
        v-model="editedItem.key"
        label="Clave"
        required
        :rules="nameRules"
      ></v-text-field>
      <v-text-field
        outlined
        color="var(--green)"
        v-model="editedItem.value"
        label="Valor"
        required
        :rules="nameRules"
      ></v-text-field>
      <v-select
        :items="[
          { text: 'No', value: 0 },
          { text: 'Si', value: 1 },
         ]"
        color="var(--green)"
        class="flex-grow-0 mb-2 transition-swing ml-auto"
        :class="$vuetify.breakpoint.xs ? '' : 'custom-width'"
        item-color="var(--green)"
        item-class="var(--green)"
        filled
        outlined
        required
        dense
        v-model="editedItem.is_active"
        :placeholder="$t('isActive')"
      >
      </v-select>
    </template>
  </base-eider-form>
</template>

<script>
import baseEiderForm from '@/components/layout/dialog/base-eider-form.vue';
import verificationRules from '@/mixins/verificationRules';

export default {
  name: 'configs-eider-form',
  props: {
    addTitle: String,
    editTitle: String,
  },
  data: () => ({
    editedItem: {
      id: null,
      key: null,
      value: null,
      is_active: null,
    },
  }),
  methods: {
    show(item = null) {
      this.$children[0].show(item);
    },
    setParams(item) {
      this.editedItem.key = item.key;
      this.editedItem.value = item.value;
      this.editedItem.is_active = item.is_active;
    },
  },
  components: {
    baseEiderForm,
  },
  mixins: [verificationRules],
};
</script>
