<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('loading')"
      :loading="loading"
      item-key="id"
      fixed-header
      :sort-desc=true
      :options.sync="options"
      :server-items-length="totalItems"
    >
      <v-progress-linear
        v-show="loading"
        slot="progress"
        color="var(--green)"
        indeterminate
        style="width: 100% !important;"
      ></v-progress-linear>

      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-col
            cols="4"
            col="auto"
            :class="{'d-flex' : $vuetify.breakpoint.smAndUp}"
          >
            <v-text-field
              v-model="searchEider"
              append-icon="mdi-magnify"
              :label="$t('search')"
              color="var(--green)"
              item-color="var(--green)"
              filled
              outlined
              dense
              class="ml-auto search-width"
              :class="{ 'mt-6' : !$vuetify.breakpoint.xs }"
              :loading="loadingSearchEider"
              @click:append="getDataFromApi"
            ></v-text-field>

            <users-eider-form
              :addTitle="$t('add1', {entity: $t('user')})"
              :editTitle="$t('edit1', {entity: $t('user')})"
              ref="usersForm"
              v-on:showSnackbar="(color, msg) => $refs.snackbar.open({ color, msg })"
              v-on:apiCall="getDataFromApi"
              v-if="refForm === 'usersForm'"
            />

            <configs-eider-form
              :addTitle="$t('add1', {entity: $t('config')})"
              :editTitle="$t('edit1', {entity: $t('config')})"
              ref="configsForm"
              v-on:showSnackbar="(color, msg) => $refs.snackbar.open({ color, msg })"
              v-on:apiCall="getDataFromApi"
              v-if="refForm === 'configsForm'"
            />

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="$refs[refForm].show()"
                  class="ml-2 pa-4 custom-btn"
                  v-bind="attrs"
                  v-on="on"
                  :class="{ 'mt-6' : !$vuetify.breakpoint.xs }"
                  color="var(--on)"
                  icon
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('add') }} </span>
            </v-tooltip>
          </v-col>
        </v-toolbar>
      </template>

      <template v-slot:[`item.updated_at`]="{ item }">
        {{ getDate(item.updated_at) }}
      </template>

      <template v-slot:item.relation_id="{ item }">
        <span>
          {{ ellipsis(item.relation_id) }}
        </span>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <span v-text="getTypeText(item.type)"></span>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="green lighten-4"
          @click="getDataFromApi"
        >
          {{ $t('refresh') }}
        </v-btn>
      </template>

      <template v-slot:[`item.is_active`]="{ item }">
        <v-chip
          class="ma-2"
          :color="getColor(item.is_active)"
          style="transform: scale(0.5)"
        >
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          @click="$refs[refForm].show(item, true)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="showDelete(item, deleteEndpoint)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer>
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-footer
              :class="`elevation-${hover ? 2 : 0}`"
              class="transition-swing transparent rounded-b-lg ma-0 pa-0"
              :height="$vuetify.breakpoint.xs ? 360 : 60"
              flat
            >
              <v-col
                cols="12"
                col="auto"
                :class="{'d-flex' : $vuetify.breakpoint.smAndUp}"
              >
                <v-select
                  :items="[
                    { text: `5 ${title}`, value: 5 },
                    { text: `10 ${title}`, value: 10 },
                    { text: `20 ${title}`, value: 20 },
                    { text: `25 ${title}`, value: 25 },
                   ]"
                  color="var(--green)"
                  class="flex-grow-0 mb-2 transition-swing ml-auto items-per-page-width"
                  :class="$vuetify.breakpoint.xs ? '' : 'custom-width'"
                  item-color="var(--green)"
                  item-class="var(--green)"
                  filled
                  outlined
                  dense
                  v-model="itemsPerPage"
                  :placeholder="title"
                  chips
                  @change="getDataFromApi()"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      color="var(--green)"
                      label
                      small
                    >
                      <p
                        class="mt-4"
                      >
                        {{ item.text }}
                      </p>
                    </v-chip>
                  </template>
                </v-select>

                <div class="ml-8 mr-7">
                  <v-row class="custom-height" style="margin: 0">
                    <div class="align-self-center mb-6 mr-2">
                      {{ totalItems }} {{ title }}
                    </div>
                    <v-btn
                      dense
                      color="var(--green)"
                      filled
                      outlined
                      medium
                      class="custom-btn ml-2"
                      :disabled="page <= 1"
                      @click="pageDown"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="page"
                          :label="$t('page')"
                          v-bind="attrs"
                          v-on="on"
                          color="var(--pink)"
                          item-color="var(--pink)"
                          filled
                          outlined
                          dense
                          type="number"
                          class="custom-width-page ml-2 mr-2"
                          min="1"
                          step="1"
                          :max="pageCount"
                          @change="getDataFromApi()"
                        ></v-text-field>
                      </template>
                      <span>{{ $t('page') }}</span>
                    </v-tooltip>
                    <div class="align-self-center mb-6 mr-2">
                      {{ $t('of') }} {{ pageCount }}
                    </div>

                    <v-btn
                      dense
                      color="var(--green)"
                      filled
                      outlined
                      medium
                      class="custom-btn mr-2"
                      :disabled="page >= pageCount"
                      @click="pageUp"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </div>

              </v-col>
            </v-footer>
          </template>
        </v-hover>
      </template>

    </v-data-table>
    <snackbarComponent ref="snackbar"></snackbarComponent>
    <delete-dialog ref="confirm"></delete-dialog>
  </v-container>
</template>

<script>
import helperDatatable from '@/mixins/helperDatatable';
import usersEiderForm from '@/components/layout/dialog/users-eider-form.vue';
import snackbarComponent from '@/components/layout/snackbar/snackbar.vue';
import deleteDialog from '@/components/layout/dialog/delete-modal.vue';
import configsEiderForm from '@/components/layout/dialog/configs-eider-form.vue';

export default {
  name: 'eider-data-table',
  data: () => ({
    loading: true,
    data: [],
    searchEider: null,
    loadingSearchEider: false,
  }),
  props: {
    headers: Array,
    title: String,
    endpoint: String,
    refForm: String,
    deleteEndpoint: String,
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    searchEider() {
      this.page = 1;
      this.getDataFromApi();
    },
  },
  components: {
    usersEiderForm,
    snackbarComponent,
    deleteDialog,
    configsEiderForm,
  },
  mixins: [helperDatatable],
};
</script>
