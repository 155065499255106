<template>
  <!-- is not responsive -->
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    permanent
    app
  >
    <v-list-item class="px-auto eider" :class="{'pa-1' : !$vuetify.breakpoint.mdAndDown}">
      <v-list-item-avatar
        @click="open"
        max-height="100"
        height="60"
        :class="{'ml-2': !mini}"
        width="auto"
        size="0"
      >
        <v-img
          style="cursor: pointer"
          src="@/assets/eider-logo.png"
          contain
          v-if="!mini"
        ></v-img>
        <v-icon
          v-else
          style="cursor: pointer"
          :class="!$vuetify.breakpoint.mdAndDown ? 'ml-3' : 'ml-1'"
        >
          mdi-menu
        </v-icon>
      </v-list-item-avatar>
      <v-spacer></v-spacer>
      <v-icon @click.stop="mini = !mini">mdi-chevron-left</v-icon>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item
        :to="{ name: 'admin' }"
        v-if="isAdmin"
      >
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{$t('admin')}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :to="{ name: 'usersAdmin' }"
        v-if="isAdmin"
      >
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{$t('users.users')}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :to="{ name: 'config' }"
        v-if="isAdmin"
      >
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{$t('config')}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :to="{ name: 'invoices' }"
        v-if="isClient"
      >
        <v-list-item-icon>
          <v-icon>mdi-note-text</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{$t('invoices')}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :to="{ name: 'delivery-notes' }"
        v-if="isClient"
      >
        <v-list-item-icon>
          <v-icon>mdi-receipt</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{$t('delivery-notes')}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :to="{ name: 'documents' }"
        v-if="isClient || isVendor || isCarrier"
      >
        <v-list-item-icon>
          <v-icon>mdi-text-box-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{$t('documents')}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :to="{ name: 'certificates' }"
        v-if="isClient"
      >
        <v-list-item-icon>
          <v-icon>mdi-certificate</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{$t('certificates')}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :to="{ name: 'invoices-vendor' }"
        v-if="isVendor"
      >
        <v-list-item-icon>
          <v-icon>mdi-note-text</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{$t('invoices-vendor')}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import roles from '@/mixins/roles';

export default {
  name: 'asidec',
  data: () => ({
    drawer: true,
    mini: false,
  }),
  methods: {
    open() {
      this.mini = true;
    },
  },
  mixins: [roles],
};
</script>

<style lang="scss">
.eider {
  background-color: var(--nav);
  height: 64px;
  @media only screen and (max-width: 965px){
    height: 56px;
  }
}
</style>
