import axios from 'axios';

const baseURL = `${process.env.VUE_APP_G4100_BASE_URL}/api`;

const g4100 = axios.create({
  baseURL,
  withCredentials: true,
});

// Add token with axios interceptors
g4100.interceptors.request.use((config) => {
  const spreadConfig = { ...config };
  spreadConfig.headers.Authorization = `Bearer ${localStorage.getItem('g4100_token')}`;
  return config;
}, (error) => Promise.reject(error));

export default g4100;
