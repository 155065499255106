<template>
  <v-dialog
    v-model="dialog"
    :max-width="maxWidth"
  >
    <v-card :loading="loadingCard">
      <template slot="progress">
        <progress-linear :loading="loadingCard"/>
      </template>
      <v-card-title>
        <span class="headline">{{ mode === 'Add' ? addTitle : editTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-form ref="form" v-model="valid">
                <slot name="fields"></slot>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="mt-n12">
        <v-spacer></v-spacer>
        <v-btn
          color="var(--green)"
          text
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          color="var(--green)"
          text
          :disabled="mode === 'Edit' ? false : !valid"
          @click="save"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProgressLinear from '@/components/layout/progress-linear.vue';

export default {
  name: 'base-eider-form',
  props: {
    addTitle: String,
    editTitle: String,
    endpoint: String,
    editedItem: Object,
    setParams: Function,
    maxWidth: {
      type: String,
      default: () => '600px',
    },
  },
  components: {
    ProgressLinear,
  },
  data: () => ({
    valid: false,
    dialog: false,
    loadingCard: false,
    mode: 'Add',
    editedIndex: -1,
  }),
  methods: {
    async add(endpoint, params) {
      await this.httpApiCall('post', endpoint, params, this.$t('addSuccess'));
    },
    async httpApiCall(method, endpoint, params, msg) {
      this.loadingCard = true;
      try {
        const res = await this.$http[method](endpoint, params);
        if (res) {
          this.close();
          this.$parent.$emit('showSnackbar', 'var(--green)', msg);
          this.$parent.$emit('apiCall');
          this.loadingCard = false;
        }
      } catch ({ ...err }) {
        this.loadingCard = false;
        this.$parent.$emit('showSnackbar', 'red', `Error: ${this.$requestError(err.response.data)}`);
      }
    },
    async edit(endpoint, params) {
      await this.httpApiCall('put', endpoint, params, this.$t('editSuccess'));
    },
    show(item = null) {
      this.mode = 'Add';
      if (item) {
        this.setParams(item);
        this.editedIndex = item.id;
        this.mode = 'Edit';
      }
      this.dialog = true;
    },
    close() {
      Object.keys(this.editedItem).forEach((key) => {
        this.editedItem[key] = null;
      });
      this.dialog = false;
      this.$refs.form.resetValidation();
    },
    save() {
      const params = this.editedItem;
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1 && this.mode === 'Edit') {
          this.edit(`${this.endpoint}/${this.editedIndex}/update`, params);
        } else {
          this.add(`${this.endpoint}/create`, params);
        }
      }
    },
  },
};
</script>
