import axios from 'axios';

const baseURL = `${process.env.VUE_APP_BASE_URL}/api`;

const http = axios.create({
  baseURL,
});

// Add token with axios interceptors
http.interceptors.request.use((config) => {
  const spreadConfig = { ...config };
  spreadConfig.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
}, (error) => Promise.reject(error));

export default http;
