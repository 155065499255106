<template>
  <v-card class="mx-auto" max-width="500" style="background-color: #C4C4C4">
    <section v-if="message">
      <p style="color: red; text-align: center" class="pa-5 ma-1" v-html="message" />
    </section>
    <div class="px-12">
      <v-img min-height="150" contain src="@/assets/eider-logo.png" v-if="showLogo"></v-img>
      <div class="pt-2" v-else></div>
    </div>
    <v-col>
      <v-form ref="form" v-model="valid" lazy-validation> <!-- Creation of login form -->
        <v-text-field
          class="mx-12"
          v-model="username"
          :rules="userRules"
          append-icon="mdi-email-outline"
          :label="$t('user')"
          filled
          dense
          color="dark"
          background-color="#FFFFFF"
        ></v-text-field> <!-- Email text field -->
        <v-text-field
          class="mx-12"
          v-model="password"
          :rules="passwordRules"
          append-icon="mdi-key-outline"
          :label="$t('password')"
          filled
          dense
          color="dark"
          type="password"
          background-color="#FFFFFF"
        ></v-text-field> <!-- Password text field -->
        <v-row class="justify-center mx-12">
          <v-btn
            elevation="3"
            class="mb-6 mt-6"
            :loading="loading"
            :disabled="!valid"
            @click="loginSubmit()"
            block
            color="#4CAF50"
          >
            Acceder
          </v-btn> <!-- Login button -->
        </v-row>
      </v-form>
    </v-col>
  </v-card>
</template>

<script>
import verificationRules from '@/mixins/verificationRules';
import login from '@/requests/login';

export default {
  name: 'login',
  data() {
    return {
      username: '',
      password: '',
      message: '',
      valid: false,
      loading: false,
      info: '',
      errored: false,
      type: null,
      showLogo: true,
    };
  },
  mixins: [verificationRules, login],
  beforeMount() {
    const exclude = [
      'https://marenostrum.testcomerline.me/',
    ];
    const { URL } = document;
    if (exclude.includes(URL)) {
      this.showLogo = false;
      if (URL === exclude[0]) {
        document.title = 'Marenostrum - Gestión documental';
      }
    }
  },
  mounted() {
    this.$store.commit('logout');
  },
};
</script>
