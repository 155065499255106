<template>
  <invoicesVendor/>
</template>

<script>
import invoicesVendor from '@/components/vendors/invoicesVendor.vue';

export default {
  components: {
    invoicesVendor,
  },
};
</script>
