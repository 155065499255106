<template>
  <v-app>
    <v-main
      class="d-flex"
      :class="{ 'align-center stage': this.$route.name === 'login' }"
    >
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  title: 'Eider',
  watch: {
    '$route.name': (val) => {
      document.documentElement.style.overflowY = 'hidden';
      if (val !== 'login') {
        document.documentElement.style.overflowY = 'auto';
      }
    },
  },
  created() {
    document.documentElement.style.overflowY = this.$route.name === 'login' ? 'hidden' : 'auto';
    document.title = 'Eider - Gestión documental';
  },
};
</script>

<style>
.stage {
  background-image:url('../public/background.png');
  width: 101vw !important;
  max-width: 101vw !important;
  background-size: cover;
  margin-top: -4px;
  margin-left: -4px;
  background-position: center;
}
.custom-height {
  height: 39.5px !important;
  min-height: 39.5px !important;
}
:root {
  --green: #94BB54;
  --tr_odd: #94BB5412;
  --tr_even: #94BB5422;
  --nav: #94BB54;
}

.v-data-table tbody tr:nth-of-type(odd) {
  background-color: var(--tr_odd);
}

.v-data-table tbody tr:nth-of-type(even) {
  background-color: var(--tr_even);
}

.custom-btn {
 width: 39px !important;
 min-width: 29px !important;
 height: 39px !important;
}
.search-width, .items-per-page-width {
  width: 300px !important;
  max-width: 300px !important;
}
</style>
