export default {
  data: () => ({
    // datatable
    page: 1,
    totalItems: 0,
    loading: false,
    loadingSearch: false,
    options: {},
    pageCount: 0,
    sortApi: {},
    itemsPerPage: 25,
    singleSelect: false,
    selected: [],
    search: null,
    pageInt: 0,
    // filters: {},
    params: {},
    filtersAux: null,
    isClicked: 0,
    // related
  }),
  methods: {
    getDataFromApi() {
      this.initializeOptions(this.options, this.sortApi);
      const params = {
        page: this.page,
        search: this.searchEider,
        limit: this.itemsPerPage,
      };
      if (Object.entries(this.sortApi).length > 0) {
        const [orderBy, direction] = Object.entries(this.sortApi).pop();
        params.order = orderBy;
        params.order_by = direction;
      }
      this.$http.get(this.url ?? this.endpoint, { params })
        .then((response) => {
          this.data = response.data.data;
          this.finalizeParams(response.data);
        });
    },
    // pagination
    pageDown() {
      this.page = parseInt(this.page, 10) - 1;
      this.getDataFromApi(this.search);
    },
    pageUp() {
      this.page = parseInt(this.page, 10) + 1;
      this.getDataFromApi(this.search);
    },
    g4100PageDown() {
      this.page = parseInt(this.page, 10) - 1;
      this.filters.salto -= 100;
      this.getDataFromG4100(this.endpoint, this.fields, this.filters, this.order);
    },
    g4100PageUp() {
      this.page = parseInt(this.page, 10) + 1;
      if (!('salto' in this.filters)) {
        this.filters.salto = 0;
      }
      this.filters.salto += 100;
      this.getDataFromG4100(this.endpoint, this.fields, this.filters, this.order);
    },
    // initialize options
    initializeOptions(options, sortApi) {
      const reassignSortApi = sortApi;
      if (options) {
        if (Object.keys(options).length !== 0) {
          const { sortBy, sortDesc } = options; // vuetify datatable
          let order;
          if (sortDesc.length === 1) order = 'ASC';
          if (sortDesc[0]) order = 'DESC';
          if (sortBy[0]) reassignSortApi[sortBy[0]] = order;
          else this.sortApi = {};
        }
      }
    },
    // finalize params to v-data-table
    finalizeParams(data) {
      if (data && data.listado) {
        this.totalItems = data.listado.length;
      } else {
        this.totalItems = data.total;
        this.page = data.page;
        this.pageCount = data.last_page;
        this.itemsPerPage = parseInt(data.items_per_page, 10);
      }
      this.loadingSearch = false;
      this.loading = false;
    },
    getTypeText(type) {
      let typeText = 'administrator';
      if (type === 1) {
        typeText = 'client';
      } else if (type === 2) {
        typeText = 'vendors';
      } else if (type === 3) {
        typeText = 'transportists';
      } else if (type === 5) {
        typeText = 'user';
      }
      return this.$t(typeText);
    },
    getSalesInvoiceStatus(id) {
      let color = 'grey';
      switch (id) {
        case '1':
          color = 'red lighten-1';
          break;
        case '2':
          color = 'lime darken-1';
          break;
        case '3':
          color = 'var(--green)';
          break;
        case '4':
          color += ' lighten-2';
          break;
        default:
          color = 'grey';
          break;
      }
      return color;
    },
    getColor(activeColor) {
      return !activeColor ? 'red' : 'green';
    },
    showDelete(item, endpoint) {
      this.$refs.confirm.showDeleteModal(item, endpoint);
    },
    findFilterDescription(filters, val) {
      const obj = filters.filtro.find(
        (filter) => filter.campo === 'descripcion' || filter.campo === 'referencia_externa' || filter.campo === 'observaciones',
      );
      if (obj) {
        obj.valor = !val ? '' : val;
      }
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] && index[0].indexOf('fecha') > -1) {
          const dateA = this.$moment(a[index], 'DD-MM-YYYY');
          const dateB = this.$moment(b[index], 'DD-MM-YYYY');
          if (!isDesc[0]) {
            return dateA.valueOf() - dateB.valueOf();
          }
          return dateB.valueOf() - dateA.valueOf();
        }
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(a[index[0]])) {
          if (!isDesc[0]) {
            return (a[index[0]] - b[index[0]]);
          }
          return (b[index[0]] - a[index[0]]);
        }
        if (!isDesc[0]) {
          return a[index[0]] < b[index[0]] ? -1 : 1;
        }
        return b[index[0]] < a[index[0]] ? -1 : 1;
      });
      return items;
    },
    ellipsis(txt, n = 25) {
      return txt && txt.length > n ? txt.slice(0, n).concat('...') : txt;
    },
  },
  watch: {
    search(val) {
      this.page = 1;
      if (!val) {
        this.findFilterDescription(this.filters);
        this.getDataFromG4100(this.endpoint, this.fields, this.filters, this.order);
      } else if (val.length >= 3) {
        this.findFilterDescription(this.filters, val);
        this.loadingSearch = true;
        this.getDataFromG4100(this.endpoint, this.fields, this.filters, this.order);
      }
    },
  },
};
