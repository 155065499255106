import types from '@/router/middleware/types';

export default {
  computed: {
    isAdmin() {
      return this.$store.getters.user.type === types.TYPE_ADMIN;
    },
    isClient() {
      return this.$store.getters.is('client');
    },
    isCarrier() {
      return this.$store.getters.is('carrier');
    },
    isVendor() {
      return this.$store.getters.is('vendor');
    },
  },
};
