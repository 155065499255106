<template>
  <documents/>
</template>

<script>
import documents from '@/components/clients/documents.vue';

export default {
  components: {
    documents,
  },
};
</script>
