<template>
  <v-btn
    icon
    @click="printPDFG4100(item, endpoint, fileName)"
  >
    <v-icon>mdi-download</v-icon>
  </v-btn>
</template>

<script>
import helperG4100 from '@/mixins/helperG4100';

export default {
  name: 'download-pdf',
  props: {
    item: Object,
    endpoint: String,
    fileName: String,
  },
  mixins: [helperG4100],
};
</script>
