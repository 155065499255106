<template>
  <div>
    <asidec v-if="isAdmin || isClient || isVendor || isCarrier"/>
    <navc/>
    <router-view/>
  </div>
</template>

<script>
import asidec from '@/components/layout/aside.vue';
import navc from '@/components/layout/nav.vue';
import roles from '@/mixins/roles';

export default {
  components: {
    asidec,
    navc,
  },
  mixins: [roles],
};
</script>
