<template>
  <login/>
</template>

<script>
import login from '../components/login.vue';

export default {
  components: {
    login,
  },
};
</script>
