<template>
  <data-table
    :headers="headers"
    :endpoint="endpoint"
    :fields="fields"
    :filters="filters"
    :actions="['pdf-button']"
    :title="$t('documents')"
    :file-name="file"
    date-filter="fecha_creacion"
    show-search
    :show-add-btn="!(!isVendor && !isCarrier && isClient)"
    :key-type="keyType"
    :order="order"
  />
</template>

<script>
import store from '@/store';
import dataTable from '@/components/layout/datatable/data-table.vue';
import roles from '@/mixins/roles';
import g4100TypesQuery from '@/utils/g4100TypesQuery';

export default {
  name: 'documents',
  components: {
    dataTable,
  },
  data: () => ({
    headers: [
      {
        text: 'Código',
        align: 'start',
        sortable: false,
        value: 'cod',
      },
      { text: 'Referencia externa', value: 'descripcion' },
      { text: 'Fecha subida', value: 'fecha_creacion' },
      { text: 'Tipo', value: 'id_tipo_documento.nombre' },
      { text: 'Observaciones', value: 'observaciones' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    endpoint: 'documentos/listar/',
    file: 'documento',
    fields: ['id', 'cod', 'descripcion', 'observaciones', 'id_tipo_documento', 'id_persona_autor', 'fecha_creacion', 'id_serie'],
    filters: {
      inicio: '1',
      filtro: [
        {
          campo: 'id_persona_autor',
          valor: String(store.getters.user.personal_id),
          tipo: g4100TypesQuery.TYPE_QUERY_EQUAL,
        },
        {
          campo: 'descripcion',
          valor: '',
          tipo: g4100TypesQuery.TYPE_QUERY_CONTAINS,
        },
      ],
    },
    order: {
      campo: 'fecha_creacion',
      orden: 'DESC',
    },
  }),
  computed: {
    keyType() {
      const types = [];
      if (this.isCarrier) {
        types.push('[TRANS]');
      }
      if (this.isClient) {
        types.push('[CLI]');
      }
      if (this.isVendor) {
        types.push('[PROV]');
      }
      return types;
    },
  },
  mixins: [roles],
};
</script>
