<template>
  <admin/>
</template>

<script>
import admin from '@/components/admin/admin.vue';

export default {
  components: {
    admin,
  },
};
</script>
