<template>
  <data-table
    :headers="headers"
    :endpoint="endpoint"
    :fields="fields"
    :filters="filters"
    :actions="['pdf-button']"
    :title="$t('certificates')"
    :file-name="file"
    :date-filter="dateFilter"
    show-search
    :order="order"
  />
</template>

<script>
import store from '@/store';
import dataTable from '@/components/layout/datatable/data-table.vue';
import g4100TypesQuery from '@/utils/g4100TypesQuery';

export default {
  name: 'certificates',
  components: {
    dataTable,
  },
  data: () => ({
    headers: [
      {
        text: 'Código',
        align: 'start',
        sortable: false,
        value: 'cod',
      },
      { text: 'Fecha de Vencimiento', value: 'fecha' },
      { text: 'Observaciones', value: 'observaciones' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    endpoint: 'certificados/listar/',
    file: 'certificado',
    fields: ['id', 'cod', 'fecha', 'persona', 'observaciones', 'notas', 'fecha', 'id_serie'],
    dateFilter: 'fecha',
    filters: {
      inicio: 0,
      filtro: [
        {
          campo: 'persona',
          valor: String(store.getters.user.personal_id),
          tipo: g4100TypesQuery.TYPE_QUERY_EQUAL,
        },
        {
          campo: 'observaciones',
          valor: '',
          tipo: g4100TypesQuery.TYPE_QUERY_CONTAINS,
        },
      ],
    },
    order: {
      campo: 'fecha_vencimiento',
      orden: 'DESC',
    },
  }),
};
</script>
