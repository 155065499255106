const TYPE_ADMIN = 0;
const TYPE_CLIENT = 1;
const TYPE_VENDOR = 2;
const TYPE_TRANSPORTIST = 3;
const TYPE_USER = 5;

export default {
  TYPE_ADMIN,
  TYPE_CLIENT,
  TYPE_VENDOR,
  TYPE_TRANSPORTIST,
  TYPE_USER,
};
