<template>
  <v-dialog
    v-model="show"
    max-width="1200"
  >
    <v-card
      v-if="show"
      class="overflow-y-hidden"
    >
      <data-table
        :headers="headers"
        :endpoint="endpoint"
        :fields="fields"
        :filters="filters"
        :actions="['pdf-button']"
        :file-name="fileName"
        :title="$t('associated-documents').toString()"
        :height="500"
        :show-date-filter="false"
      >
      </data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="var(--green)"
          text
          @click="show = false"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dataTable from '@/components/layout/datatable/data-table.vue';
import g4100TypesQuery from '@/utils/g4100TypesQuery';

export default {
  name: 'modal',
  props: {
    headers: Array,
    endpoint: String,
    fields: Array,
  },
  components: {
    dataTable,
  },
  data: () => ({
    show: false,
    filters: {
      inicio: 0,
      filtro: [],
    },
    fileName: 'documento_asociado',
  }),
  methods: {
    async open(item) {
      this.show = true;
      this.item = item;
      const field = this.endpoint.split('/')[0].replace('union_documentos_', '');
      this.filters.filtro = [
        { campo: field, valor: this.item.id, tipo: g4100TypesQuery.TYPE_QUERY_EQUAL },
      ];
    },
  },
};
</script>
