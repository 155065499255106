var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"sort-by":"code","items-per-page":_vm.itemsPerPageProp,"sort-desc":true,"hide-default-footer":"","fixed-header":"","height":_vm.height,"loading-text":_vm.$t('loading'),"loading":_vm.loading,"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.showSearch)?_c('v-col',{class:{'d-flex' : _vm.$vuetify.breakpoint.smAndUp},attrs:{"cols":"4","col":"auto"}},[_c('v-text-field',{staticClass:"ml-auto search-width",class:{ 'mt-6' : !_vm.$vuetify.breakpoint.xs },attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"color":"var(--green)","item-color":"var(--green)","filled":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),(_vm.showDateFilter)?_c('v-select',{staticClass:"mt-6 search-width",attrs:{"filled":"","outlined":"","dense":"","color":"var(--green)","item-color":"var(--green)","label":_vm.$t('date'),"items":_vm.dates},on:{"change":_vm.changeDateFilter},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}}):_vm._e(),(_vm.showAddBtn)?_c('add-form',{ref:"addForm",attrs:{"title":_vm.$t('add-document'),"types":_vm.addForm.types,"on-submit":_vm.addEntity}}):_vm._e(),_vm._t("headerActions")],2)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.actions.find(function (a) { return a === 'pdf-button'; }))?_c('download-pdf',{attrs:{"item":item,"endpoint":_vm.endpoint,"file-name":_vm.fileName}}):_vm._e(),(_vm.modal)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.dataTableModal.open(item)}}},[_c('v-icon',[_vm._v("mdi-text-box-multiple")])],1):_vm._e(),_vm._t("actions",null,{"item":item})]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$numFormatter(item.total))+" ")]}},{key:"item.id_situacion_cobro",fn:function(ref){
var item = ref.item;
return [(item.id_situacion_cobro)?_c('v-chip',{attrs:{"color":_vm.getSalesInvoiceStatus(item.id_situacion_cobro.id),"small":"","label":""}},[_vm._v(" "+_vm._s(item.id_situacion_cobro.nombre)+" ")]):_vm._e()]}},{key:"item.id_situacion_pago",fn:function(ref){
var item = ref.item;
return [(item.id_situacion_pago)?_c('v-chip',{attrs:{"color":_vm.getSalesInvoiceStatus(item.id_situacion_pago.id),"small":"","label":""}},[_vm._v(" "+_vm._s(item.id_situacion_pago.nombre)+" ")]):_vm._e()]}},{key:"item.relation_id",fn:function(ref){
var item = ref.item;
return [(
        item[_vm.fieldPerson] && (
          _vm.$store.getters.user.relation_id.indexOf(item[_vm.fieldPerson].id) > -1
          || item[_vm.fieldPerson].id == _vm.$store.getters.user.personal_id
        )
        )?_c('span',[_vm._v(" ["+_vm._s(item[_vm.fieldPerson].cod)+"] "+_vm._s(item[_vm.fieldPerson].nombre)+" ")]):_vm._e()]}},{key:"item.id_serie",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" ["+_vm._s(item.id_serie.cod)+"] "+_vm._s(item.id_serie.nombre)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"#94BB54"},on:{"click":function($event){return _vm.getDataFromG4100(_vm.endpoint, _vm.fields, _vm.filters, _vm.order)}}},[_vm._v(" "+_vm._s(_vm.$t('refresh'))+" ")])]},proxy:true},{key:"footer",fn:function(){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-footer',{staticClass:"transition-swing transparent rounded-b-lg ma-0 pa-0",class:("elevation-" + (hover ? 2 : 0)),attrs:{"height":_vm.$vuetify.breakpoint.xs ? 360 : 60,"flat":""}},[_c('v-col',{class:{'d-flex flex-row-reverse' : _vm.$vuetify.breakpoint.smAndUp},attrs:{"cols":"12","col":"auto"}},[_c('div',{staticClass:"mr-0"},[_c('v-row',{staticClass:"custom-height",staticStyle:{"margin":"0"}},[_c('v-btn',{staticClass:"custom-btn ml-2",attrs:{"dense":"","color":"var(--green)","filled":"","outlined":"","medium":"","disabled":_vm.page <= 1},on:{"click":_vm.g4100PageDown}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-2 mr-2",staticStyle:{"width":"70px"},attrs:{"label":_vm.$t('page'),"filled":"","outlined":"","dense":"","disabled":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('page')))])]),_c('v-btn',{staticClass:"custom-btn mr-2",attrs:{"dense":"","color":"var(--green)","filled":"","outlined":"","medium":"","disabled":_vm.data.length < 100},on:{"click":_vm.g4100PageUp}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)])],1)]}}])})]},proxy:true}],null,true)},[_c('template',{slot:"progress"},[_c('progress-linear',{attrs:{"loading":_vm.loading}})],1)],2),_c('snackbar',{ref:"snackbar"}),(_vm.modal)?_c('data-table-modal',{ref:"dataTableModal",attrs:{"headers":_vm.modal.headers,"endpoint":_vm.modal.endpoint,"fields":_vm.modal.fields}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }