import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const getDefaultState = () => ({
  g4100Token: '',
  token: '',
  user: {},
  type: null,
});

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token'),
    g4100Token: localStorage.getItem('g4100_token'),
    user: JSON.parse(localStorage.getItem('user')),
    type: localStorage.getItem('type'),
  },
  mutations: {
    token(state, token) {
      state.token = token;
    },
    user(state, user) {
      state.user = user;
    },
    type(state, type) {
      state.type = type;
    },
    logout(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    token: (state) => state.token,
    g4100Token: (state) => state.g4100Token,
    user: (state) => state.user,
    is: (state) => (type) => type in state.user && Object.keys(state.user[`${type}`]).length > 0,
    type: (state) => state.type,
    isLoggedIn(state) {
      return state.user !== null;
    },
  },
  actions: {
  },
  modules: {
  },
});
