import http from '@/requests/endpoints/axios/axiosInstance';
import helperG4100 from '../mixins/helperG4100';

export default {
  methods: {
    async loginSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.loginEiderAPI();
      }
    },
    async loginEiderAPI() {
      http.post('login', {
        username: this.username,
        password: this.password,
      }).then((response) => {
        if (response.data.token) {
          const res = response.data;
          localStorage.setItem('token', res.token);
          this.$store.commit('token', res.token);
          http.defaults.headers.common.Authorization = `Bearer ${res.token}`;
          localStorage.setItem('user', JSON.stringify(res.user));
          this.$store.commit('user', res.user);
          this.type = res.user.type;
          this.loginG4100(res.user);
        }
      }).catch(() => {
        this.message = this.$t('errors.login');
        this.errored = true;
        this.loading = false;
      });
    },
  },
  mixins: [helperG4100],
};
