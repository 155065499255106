import g4100 from '@/requests/endpoints/axios/g4100Instance';
import responseStatus from '@/requests/endpoints/axios/constans';
import g4100TypesQuery from '@/utils/g4100TypesQuery';

export default {
  computed: {
    dates() {
      const now = new Date().toLocaleDateString().split('/');
      const year = now[2];
      const years = [];
      for (let i = 0; i < 4; i += 1) {
        years.push(Number(year) - i);
      }
      // eslint-disable-next-line prefer-destructuring
      this.selectedDate = years[0];
      return years;
    },
  },
  methods: {
    checkIfNotEmpty: (check) => check && check !== '',
    async loginG4100(login = false) {
      const { user } = this.$store.getters;
      const username = this.checkIfNotEmpty(user?.connection?.username)
        ? user?.connection?.username
        : process.env.VUE_APP_G4100_USER;
      const pass = this.checkIfNotEmpty(user?.connection?.password)
        ? user?.connection?.password
        : process.env.VUE_APP_G4100_PASSWORD;
      g4100.defaults.baseURL = this.checkIfNotEmpty(user?.connection?.host)
        ? `${user?.connection?.host}/api`
        : `${process.env.VUE_APP_G4100_BASE_URL}/api`;
      g4100.post('users/login', {
        name: username,
        password: pass,
      }).then((response) => {
        if (response.data.success) {
          localStorage.setItem('g4100_token', response.data.success.token);
          g4100.defaults.headers.common.Authorization = `Bearer ${response.data.success.token}`;
          if (login) { // If we are only refreshing the token, we don't have to redirect
            this.$router.push({ name: 'admin' });
          }
        }
      });
    },
    checkToken() {
      g4100.interceptors.response.use((response) => response,
        (error) => {
          const {
            response: { status },
          } = error;
          if (status === 401) {
            this.loginG4100();
          }
        });
    },
    getDataFromG4100(url, fields, filters, order) {
      this.loading = true;
      this.checkToken();
      if ('filtro' in filters && filters.filtro.length !== 0) {
        g4100.post('g4100/list', {
          endpoint: url,
          fields: JSON.stringify(fields),
          filters: JSON.stringify(filters),
          order: JSON.stringify(order),
        }).then((response) => {
          this.data = response.data.listado;
          this.finalizeParams(response.data);
        }).catch((err) => {
          if (this.$isNotProd()) {
            // eslint-disable-next-line no-console
            console.log(err);
          }
        });
      }
    },
    async g4100Post({
      url,
      endpoint,
      fields,
      filters,
      datas,
      order,
      content,
    }) {
      const apiCall = async () => {
        const { data } = await g4100.post(url, {
          endpoint,
          fields: JSON.stringify(fields),
          filters: JSON.stringify(filters),
          order: JSON.stringify(order),
          datas: JSON.stringify(datas),
          content,
        });
        return data;
      };
      try {
        return await apiCall();
      } catch (e) {
        await this.loginG4100();
        return apiCall();
      }
    },
    async getTypes() {
      const { listado } = await this.g4100Post({
        url: 'g4100/list',
        endpoint: 'tipos_documento/listar/',
        fields: ['id', 'cod', 'nombre'],
        filters: { inicio: 0, filtro: [] },
        order: { campo: 'id', orden: 'ASC' },
      });
      return listado;
    },
    async getListEntity({ addDateFilter = true } = {}) {
      this.loading = true;
      if (!this.selectedDateChanged && addDateFilter && this.showDateFilter) {
        this.setDateFilter();
      }
      if (this.keyType) {
        await this.setTypeFilter();
      }
      this.getDataFromG4100(
        this.endpoint,
        this.fields,
        this.filters,
        this.order,
      );
    },
    isTypeInFilters(type) {
      return this.filters.filtro.find((f) => f.valor === type.id && f.campo === 'id_tipo_documento');
    },
    async setTypeFilter() {
      const types = await this.getTypes();
      this.addForm.types = this.filterTypes(types, true, this.keyType);
      const typesWithoutKeyType = this.filterTypes(types, false, this.keyType);
      typesWithoutKeyType.forEach((type) => {
        if (!this.isTypeInFilters(type)) {
          this.filters.filtro.push({
            campo: 'id_tipo_documento',
            valor: String(type.id),
            tipo: g4100TypesQuery.TYPE_QUERY_DISTINCT,
          });
        }
      });
    },
    setDateFilter() {
      const [currentYear, nextYear] = this.getYearsDateFilters();
      const dateFilter = [
        {
          campo: this.dateFilter,
          valor: currentYear,
          tipo: g4100TypesQuery.TYPE_QUERY_GREATER_THAN,
        },
        {
          campo: this.dateFilter,
          valor: nextYear,
          tipo: g4100TypesQuery.TYPE_QUERY_LESS_THAN,
        },
      ];
      dateFilter.forEach((dF) => this.filters.filtro.push(dF));
    },
    getYearsDateFilters() {
      const currentYear = `${this.selectedDate}-01-01`;
      const nextYear = `${this.selectedDate + 1}-01-01`;
      return [currentYear, nextYear];
    },
    changeDateFilter() {
      this.page = 1;
      this.filters.inicio = 1;
      this.selectedDateChanged = true;
      const [currentYear, nextYear] = this.getYearsDateFilters();
      this.filters.filtro[
        this.findIndexCreationDate(g4100TypesQuery.TYPE_QUERY_GREATER_THAN)
      ].valor = currentYear;
      this.filters.filtro[
        this.findIndexCreationDate(g4100TypesQuery.TYPE_QUERY_LESS_THAN)
      ].valor = nextYear;
      this.getListEntity();
    },
    findIndexCreationDate(type) {
      return this.filters.filtro.findIndex((f) => f.campo === this.dateFilter && f.tipo === type);
    },
    async addEntity(entity) {
      const data = await this.g4100Post({
        url: 'g4100/create',
        endpoint: 'documentos',
        fields: ['descripcion', 'observaciones', 'id_tipo_documento', 'id_persona_autor', 'nombre_original'],
        datas: [
          entity.externalRef,
          entity.observations,
          entity.selectedType,
          String(this.$store.getters.user.personal_id),
          entity.file.name,
        ],
        content: await this.fileToBase64(entity.file),
      });
      let color = 'red';
      let msg = this.$t('not-added');
      if (data && data.estado === 'ok') {
        const idDocument = data.id;
        const idIssue = await this.createIssue({
          description: `Se ha subido correctamente el documento con id: ${idDocument}`,
        });
        await this.linkDocumentAndIssue(idIssue, idDocument);
        await this.$http.post('send-mail', {
          type: 'uploaded-document',
          doc_id: data.id,
        });
        color = 'var(--green)';
        msg = this.$t('document-created');
        await this.getListEntity({ addDateFilter: false });
        this.$refs.addForm.dialog = false;
        this.$refs.addForm.loading = false;
      }
      this.$refs.snackbar.open({ color, msg });
    },
    async createIssue({
      description,
    }) {
      const { user } = this.$store.getters;
      const agentId = user.agent_id;
      const date = new Date().toISOString();
      const fields = ['persona', 'descripcion', 'entrada', 'tipo'];
      const datas = [
        user.personal_id,
        description,
        `${date.substr(0, 10)} ${date.substr(11, 8)}`,
        process.env.VUE_APP_TYPE_OF_ISSUE_IN_CREATION ?? 47,
      ];
      if (agentId) {
        fields.unshift('anotador', 'responsable');
        datas.unshift(agentId.id, agentId.id);
      }
      const issue = await this.g4100Post({
        url: 'g4100/create',
        endpoint: 'incidencias',
        fields,
        datas,
      });
      let idIssue = null;
      if (issue && issue.estado === 'ok') {
        idIssue = issue.id;
      } else {
        this.$refs.snackbar.open({
          color: 'red',
          msg: this.$t('not-added-issue'),
        });
      }
      return idIssue;
    },
    linkDocumentAndIssueFeedBack(success) {
      let color = 'red';
      let msg = 'link-document-issue-error';
      if (success) {
        color = 'var(--green)';
        msg = 'link-document-issue';
      }
      this.$refs.snackbar.open({
        color,
        msg: this.$t(msg),
      });
    },
    async linkDocumentAndIssue(idIssue, idDocument) {
      if (!idIssue || !idDocument) {
        this.linkDocumentAndIssueFeedBack(false);
        return;
      }
      const reqLinkIssueWithDocument = this.g4100Post({
        url: 'g4100/create',
        endpoint: 'union_incidencias_documentos',
        fields: ['incidencia', 'documentos'],
        datas: [String(idIssue), String(idDocument)],
      });
      const reqLinkDocumentWithIssue = this.g4100Post({
        url: 'g4100/create',
        endpoint: 'union_documentos_incidencias',
        fields: ['documento', 'incidencias'],
        datas: [String(idDocument), String(idIssue)],
      });
      const res = await Promise.all([reqLinkIssueWithDocument, reqLinkDocumentWithIssue]);
      if (!(res[0].estado === responseStatus.success && res[1].estado === responseStatus.success)) {
        this.linkDocumentAndIssueFeedBack(false);
      }
    },
    fileToBase64: (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.replace('data:application/pdf;base64,', ''));
      reader.onerror = (error) => reject(error);
    }),
    filterTypes(paramTypes, check, key) {
      const regex = /\[.*?\]/g;
      return paramTypes.filter(
        ({ nombre }) => {
          if (!nombre.match(regex)) return false;
          return (
            check
              ? key.indexOf(nombre.match(regex)[0]) > -1
              : key.indexOf(nombre.match(regex)[0]) === -1
          );
        },
      );
    },
    printPDFG4100(item, endpoint, fileName) {
      const endpointSplit = endpoint.split('/');
      let downloadEndpoint = endpointSplit[0];
      let { id } = item;
      if (downloadEndpoint.includes('union_documentos_')) {
        downloadEndpoint = 'documentos';
        id = item.documento.id;
      }
      let url = 'g4100';
      if (downloadEndpoint === 'documentos') {
        url = `${url}/download`;
      } else {
        url = `${url}/print`;
      }
      g4100.post(url, {
        responseType: 'arraybuffer',
        endpoint: downloadEndpoint,
        id,
      }, { responseType: 'arraybuffer' }).then((response) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([response.data], { type: 'application/pdf' });
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = `${this.generateFileName(fileName, item)}.pdf`;
        link.click();
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
    },
    generateFileName(fileName, item) {
      let serie = '';
      if (item.id_serie) {
        serie = `_${item.id_serie.cod}`;
      }
      let cod = '';
      if (item.cod) {
        cod = `_${item.cod}`;
      } else if (item.documento) {
        cod = `_${item.documento.cod}`;
      }
      return `${fileName}${cod}${serie}`;
    },
    logoutG4100() {
      g4100.delete('users/logout').then(() => {
        this.$logout();
      });
    },
  },
};
