<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="transparent"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-card :loading="loading">
        <template slot="progress">
          <progress-linear :loading="loading"/>
        </template>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addForm" ref="form">
              <v-row dense>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    outlined
                    color="#94BB54"
                    v-model="editedItem.externalRef"
                    label="Referencia externa"
                    :rules="required"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    outlined
                    color="#94BB54"
                    v-model="editedItem.observations"
                    :label="$t('observations')"
                    :rules="required"
                  ></v-textarea>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-file-input
                    outlined
                    append-icon="mdi-paperclip"
                    prepend-icon=""
                    color="#94BB54"
                    v-model="editedItem.file"
                    :label="$t('add1', {entity: $t('file').toLowerCase()})"
                    truncate-length="15"
                    show-size
                    :rules="fileRules"
                    accept="application/pdf"
                  ></v-file-input>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    outlined
                    color="var(--green)"
                    item-color="var(--green)"
                    v-model="editedItem.selectedType"
                    :label="$t('type')"
                    :items="types"
                    item-text="nombre"
                    item-value="id"
                    :rules="required"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n8">
          <v-spacer></v-spacer>
          <v-btn
            color="var(--green)"
            text
            @click="editedItem = defaultItem; dialog = false"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
            color="var(--green)"
            text
            @click="onSubmit(editedItem); loading = true"
            :disabled="!addForm"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import verificationRules from '@/mixins/verificationRules';
import helperG4100 from '@/mixins/helperG4100';
import ProgressLinear from '@/components/layout/progress-linear.vue';

export default {
  name: 'add-form',
  components: {
    ProgressLinear,
  },
  data: () => ({
    dialog: false,
    addForm: false,
    loading: false,
    defaultItem: {
      externalRef: null,
      observations: null,
      file: null,
      types: [],
      selectedType: null,
    },
    editedItem: {
      externalRef: null,
      observations: null,
      file: null,
      types: [],
      selectedType: null,
    },
  }),
  props: {
    title: String,
    onSubmit: Function,
    types: Array,
  },
  watch: {
    dialog() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.editedItem.observations = null;
      this.editedItem.externalRef = null;
      this.editedItem.file = null;
      this.editedItem.selectedType = null;
    },
  },
  mixins: [verificationRules, helperG4100],
};
</script>

<style lang="scss">
.v-file-input__text {
  cursor: pointer !important;
}
</style>
