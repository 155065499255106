<template>
  <v-dialog
    v-model="deleteModal"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t('deleteRegistry') }}
      </v-card-title>
      <v-card-text>
        {{ $t('deleteConfirm') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="deleteModal = false"
        >
          No
        </v-btn>
        <v-btn
          color="red"
          text
          @click="destroy(`${endpoint}/${deleteItem.id}/delete`)"
        >
          Si
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import http from '@/requests/endpoints/axios/axiosInstance';

export default {
  data: () => ({
    deleteModal: false,
    endpoint: '',
  }),
  methods: {
    showDeleteModal(item, endpoint) {
      this.deleteModal = true;
      this.deleteItem = item;
      this.endpoint = endpoint;
    },
    async destroy(endpoint) {
      await http.post(endpoint).then(
        () => {
          this.$parent.data.splice(this.$parent.data.indexOf(this.$parent.deleteItem), 1);
          this.deleteModal = false;
          this.$parent.text = this.$t('deleteSuccess');
          this.$parent.color = '#94BB54';
          this.$parent.snackbar = true;
          this.$parent.getDataFromApi();
        },
        (err) => {
          if (this.$isNotProd()) {
            console.log(err);
          }
          this.$parent.text = this.$t('errors.delete');
          this.$parent.color = '#e41212';
          this.$parent.snackbar = true;
        },
      );
    },
  },
};
</script>
