<template>
  <users/>
</template>

<script>
import users from '@/components/admin/users.vue';

export default {
  components: {
    users,
  },
};
</script>
