<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="data"
      sort-by="code"
      :items-per-page="itemsPerPageProp"
      :sort-desc=true
      hide-default-footer
      class="elevation-1"
      fixed-header
      :height="height"
      :loading-text="$t('loading')"
      :loading="loading"
      :custom-sort="customSort"
    >
      <template slot="progress">
        <progress-linear :loading="loading"/>
      </template>

      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-col
            cols="4"
            col="auto"
            :class="{'d-flex' : $vuetify.breakpoint.smAndUp}"
            v-if="showSearch"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              color="var(--green)"
              item-color="var(--green)"
              class="ml-auto search-width"
              filled
              outlined
              dense
              :class="{ 'mt-6' : !$vuetify.breakpoint.xs }"
            ></v-text-field>
          </v-col>
          <v-select
            v-if="showDateFilter"
            class="mt-6 search-width"
            filled
            outlined
            dense
            color="var(--green)"
            item-color="var(--green)"
            v-model="selectedDate"
            :label="$t('date')"
            :items="dates"
            @change="changeDateFilter"
          ></v-select>
          <add-form
            ref="addForm"
            v-if="showAddBtn"
            :title="$t('add-document')"
            :types="addForm.types"
            :on-submit="addEntity"
          />
          <slot name="headerActions"></slot>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <download-pdf
          v-if="actions.find((a) => a === 'pdf-button')"
          :item="item"
          :endpoint="endpoint"
          :file-name="fileName"
        />
        <v-btn
          v-if="modal"
          icon
          @click="$refs.dataTableModal.open(item)"
        >
          <v-icon>mdi-text-box-multiple</v-icon>
        </v-btn>
        <slot name="actions" v-bind:item="item"></slot>
      </template>

      <template v-slot:item.total="{ item }"> {{ $numFormatter(item.total) }} </template>

      <template v-slot:item.id_situacion_cobro="{ item }">
        <v-chip
          v-if="item.id_situacion_cobro"
          :color="getSalesInvoiceStatus(item.id_situacion_cobro.id)"
          small
          label
        >
          {{ item.id_situacion_cobro.nombre }}
        </v-chip>
      </template>

      <template v-slot:item.id_situacion_pago="{ item }">
        <v-chip
          v-if="item.id_situacion_pago"
          :color="getSalesInvoiceStatus(item.id_situacion_pago.id)"
          small
          label
        >
          {{ item.id_situacion_pago.nombre }}
        </v-chip>
      </template>

      <template v-slot:item.relation_id="{ item }">
        <span
          v-if="
          item[fieldPerson] && (
            $store.getters.user.relation_id.indexOf(item[fieldPerson].id) > -1
            || item[fieldPerson].id == $store.getters.user.personal_id
          )
          "
        >
          [{{ item[fieldPerson].cod }}] {{item[fieldPerson].nombre}}
        </span>
      </template>

      <template v-slot:item.id_serie="{ item }">
        <span>
          [{{ item.id_serie.cod }}] {{item.id_serie.nombre}}
        </span>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="#94BB54"
          @click="getDataFromG4100(endpoint, fields, filters, order)"
        >
          {{ $t('refresh') }}
        </v-btn>
      </template>
      <template v-slot:footer>
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-footer
              :class="`elevation-${hover ? 2 : 0}`"
              class="transition-swing transparent rounded-b-lg ma-0 pa-0"
              :height="$vuetify.breakpoint.xs ? 360 : 60"
              flat
            >
              <v-col
                cols="12"
                col="auto"
                :class="{'d-flex flex-row-reverse' : $vuetify.breakpoint.smAndUp}"
              >
                <div class="mr-0">
                  <v-row class="custom-height" style="margin: 0">
                    <v-btn
                      dense
                      color="var(--green)"
                      filled
                      outlined
                      medium
                      class="custom-btn ml-2"
                      :disabled="page <= 1"
                      @click="g4100PageDown"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="page"
                          :label="$t('page')"
                          v-bind="attrs"
                          v-on="on"
                          filled
                          outlined
                          dense
                          class="ml-2 mr-2"
                          style="width: 70px;"
                          disabled
                        ></v-text-field>
                      </template>
                      <span>{{ $t('page') }}</span>
                    </v-tooltip>

                    <v-btn
                      dense
                      color="var(--green)"
                      filled
                      outlined
                      medium
                      class="custom-btn mr-2"
                      @click="g4100PageUp"
                      :disabled="data.length < 100"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </div>

              </v-col>
            </v-footer>
          </template>
        </v-hover>
      </template>
    </v-data-table>
    <snackbar ref="snackbar"></snackbar>
    <data-table-modal ref="dataTableModal"
     v-if="modal"
     :headers="modal.headers"
     :endpoint="modal.endpoint"
     :fields="modal.fields"
    />
  </v-container>
</template>

<script>
import helperDatatable from '@/mixins/helperDatatable';
import helperG4100 from '@/mixins/helperG4100';
import downloadPdf from '@/components/layout/button/download-pdf.vue';
import addForm from '@/components/layout/dialog/add-form.vue';
import ProgressLinear from '@/components/layout/progress-linear.vue';
import g4100TypesQuery from '@/utils/g4100TypesQuery';

export default {
  name: 'data-table',
  data: () => ({
    data: [],
    loading: true,
    selectedDate: null,
    selectedDateChanged: false,
    addForm: {
      title: null,
      types: [],
    },
    fieldPerson: null,
  }),
  props: {
    headers: Array,
    endpoint: String,
    fields: Array,
    filters: Object,
    order: {
      type: Object,
      default: () => ({
        campo: 'fecha',
        orden: 'DESC',
      }),
    },
    keyType: Array,
    onAddBtn: Function,
    itemsPerPageProp: {
      type: Number,
      default: 100,
    },
    headerActions: Array,
    actions: {
      type: Array,
      default: () => [],
    },
    fileName: String,
    showSearch: Boolean,
    showAddBtn: Boolean,
    title: {
      type: String,
      required: () => true,
    },
    dateFilter: {
      type: String,
      default: () => 'fecha',
    },
    modal: Object,
    height: {
      types: Number,
      default: () => 700,
    },
    showDateFilter: {
      type: Boolean,
      default: () => true,
    },
  },
  mixins: [helperG4100, helperDatatable],
  created() {
    this.filters.inicio = 0;
    const relationId = this.$store.getters.user.relation_id;
    if (relationId) {
      const personId = this.filters.filtro.find((filter) => filter.campo.indexOf('persona') > -1 || filter.campo === 'id_proveedor');
      if (personId) {
        let dateIndex = this.headers.findIndex((header) => header.value.indexOf('fecha') > -1);
        if (dateIndex) dateIndex += 1;
        this.headers.splice(dateIndex || this.headers.length - 1, 0, { text: 'Persona', value: 'relation_id', sortable: false });
        this.fieldPerson = personId.campo;
        personId.valor = `${personId.valor},${relationId}`;
        personId.tipo = g4100TypesQuery.TYPE_QUERY_SET_OF_IDS;
      }
    }
  },
  async mounted() {
    await this.getListEntity();
  },
  components: {
    downloadPdf,
    addForm,
    ProgressLinear,
  },
};
</script>

<style>
.v-btn > .v-btn__content .v-icon {
  color: grey;
  background-color: transparent;
}
</style>
