import types from '@/router/middleware/types';

const redirect = (next, store) => {
  localStorage.clear();
  store.commit('logout');
  return next({ name: 'login' });
};

const carrier = ({ next, store }) => {
  if (!store.getters.is('carrier')) {
    return redirect(next, store);
  }
  return next();
};

const client = ({ next, store }) => {
  if (!store.getters.is('client')) {
    return redirect(next, store);
  }
  return next();
};

const vendor = ({ next, store }) => {
  if (!store.getters.is('vendor')) {
    return redirect(next, store);
  }
  return next();
};

const auth = ({ next, store }) => {
  if (!store.getters.token) {
    return redirect(next, store);
  }
  return next();
};

const admin = ({ next, store }) => {
  if (store.getters.user?.type !== types.TYPE_ADMIN) {
    return redirect(next, store);
  }
  return next();
};

const documents = ({ next, store }) => {
  const isClient = store.getters.is('client');
  const isVendor = store.getters.is('vendor');
  const isCarrier = store.getters.is('carrier');
  if ((!isClient && !isVendor && !isCarrier)) {
    return redirect(next, store);
  }
  return next();
};

export default {
  carrier,
  client,
  auth,
  admin,
  vendor,
  documents,
};
