<template>
  <deliveryNotes/>
</template>

<script>
import deliveryNotes from '../../components/clients/deliveryNotes.vue';

export default {
  components: {
    deliveryNotes,
  },
};
</script>
