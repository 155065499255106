import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import snackbar from '@/components/layout/snackbar/snackbar.vue';
import deleteModal from '@/components/layout/dialog/delete-modal.vue';
import http from '@/requests/endpoints/axios/axiosInstance';
import dataTableModal from '@/components/layout/dialog/data-table-modal.vue';
import moment from 'moment';

Vue.config.productionTip = false;

Vue.prototype.$logout = () => {
  // localStorage
  localStorage.clear();
  // set default app state
  store.commit('logout');
  // cookies
  const cookies = document.cookie.split(';');
  cookies.length = 0;
  // send to login
  router.push({ name: 'login' });
};
Vue.prototype.$isNotProd = () => process.env.NODE_ENV !== 'production';
Vue.prototype.$http = http;
Vue.prototype.$moment = moment;

Vue.prototype.$requestError = (err) => {
  let msg = '';
  if (typeof err === 'object' && err !== null) {
    Object.values(err).forEach((e) => {
      msg += `${e}<br/>`;
    });
  } else if (err && Array.isArray(err)) {
    err.forEach((e) => {
      msg += `${e}<br/>`;
    });
  } else {
    // eslint-disable-next-line no-console
    console.error(err);
    msg = err;
  }
  return msg;
};

Vue.prototype.$generatePassword = (size, characters = 'a-z,A-Z,0-9,#') => {
  const charactersArray = characters.split(',');
  let characterSet = '';
  let password = '';

  if (charactersArray.indexOf('a-z') >= 0) {
    characterSet += 'abcdefghijklmnopqrstuvwxyz';
  }
  if (charactersArray.indexOf('A-Z') >= 0) {
    characterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }
  if (charactersArray.indexOf('0-9') >= 0) {
    characterSet += '0123456789';
  }
  if (charactersArray.indexOf('#') >= 0) {
    characterSet += '![]{}()%&*$#^<>~|';
  }

  for (let i = 0; i < size; i += 1) {
    password += characterSet.charAt(Math.floor(Math.random() * characterSet.length));
  }

  return password;
};

Vue.prototype.$numFormatter = (num) => {
  const fNum = parseFloat(num) ? parseFloat(num) : 0;
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });
  return formatter.format(fNum).replace('€', '');
};

Vue.component('snackbar', snackbar);
Vue.component('data-table-modal', dataTableModal);
Vue.component('delete-dialog', deleteModal);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
