<template>
  <v-snackbar
    v-model="show"
    :color="color"
    :timeout="5000"
  >
    <div v-html="msg"></div>

    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="show = false"
      >
        {{ $t('close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'snackbar',
  data: () => ({
    show: false,
    color: 'red',
    msg: '',
  }),
  methods: {
    open({ color, msg }) {
      this.show = true;
      this.color = color;
      this.msg = msg;
    },
  },
};
</script>
