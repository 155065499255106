<template>
  <data-table
    :headers="headers"
    :endpoint="endpoint"
    :fields="fields"
    :filters="filters"
    :actions="['pdf-button']"
    :title="$t('invoices')"
    :file-name="file"
    :modal="modal"
    show-search
  />
</template>

<script>
import store from '@/store';
import dataTable from '@/components/layout/datatable/data-table.vue';
import g4100TypesQuery from '@/utils/g4100TypesQuery';

export default {
  name: 'invoices',
  components: {
    dataTable,
  },
  data: () => ({
    headers: [
      {
        text: 'Código',
        align: 'start',
        sortable: false,
        value: 'cod',
      },
      { text: 'Serie', value: 'id_serie', sortable: false },
      { text: 'Fecha', value: 'fecha' },
      { text: 'Referencia externa', value: 'referencia_externa' },
      { text: 'Observaciones', value: 'observaciones' },
      { text: 'Importe', value: 'total', align: 'end' },
      { text: 'Situación', value: 'id_situacion_cobro', sortable: false },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    endpoint: 'facturasV/listar/',
    file: 'factura',
    fields: ['id', 'cod', 'fecha', 'id_tipo', 'id_persona', 'referencia_externa', 'observaciones', 'total', 'id_situacion_cobro', 'id_serie'],
    filters: {
      inicio: 1,
      filtro: [
        {
          campo: 'id_persona',
          valor: String(store.getters.user.personal_id),
          tipo: g4100TypesQuery.TYPE_QUERY_EQUAL,
        },
        {
          campo: 'referencia_externa',
          valor: '',
          tipo: g4100TypesQuery.TYPE_QUERY_CONTAINS,
        },
      ],
    },
    modal: {
      headers: [
        {
          text: 'Código',
          align: 'start',
          sortable: false,
          value: 'documento.cod',
        },
        { text: 'Documento', value: 'documento.nombre' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      fields: ['id', 'documento', 'facturasV'],
      endpoint: 'union_documentos_facturasV/listar',
    },
  }),
};
</script>
