export default {
  data() {
    return {
      password: '',
      username: '',
      validated: false,
    };
  },
  methods: {
    validateForm() {
      this.validated = !!this.$refs.form.validate();
    },
  },
  computed: {
    required() {
      return [
        (v) => !!v || this.$t('required-field'),
      ];
    },
    userRules() {
      return [
        (v) => !!v || this.$t('users.required'),
      ];
    },
    emailsRules() {
      return [
        (v) => !!v || this.$t('emails.required'),
      ];
    },
    nameRules() {
      return [
        (v) => !!v || this.$t('name.required'),
      ];
    },
    personalIDRules() {
      return [
        (v) => v <= 32767 || this.$t('personalId.invalid'),
      ];
    },
    passwordRules() {
      return [
        (v) => !!v || this.$t('passwords.required'),
        (v) => (v && v.length >= 8) || this.$t('passwords.length'),
      ];
    },
    passwordRulesEdit() {
      return [
        (v) => (v && v.length >= 8) || this.$t('passwords.length'),
      ];
    },
    passwordRulesWhenEmpty() {
      return [];
    },
    fileRules() {
      return [
        ...this.required,
        (v) => (v && v.type === 'application/pdf') || this.$t('pdf-format'),
        (v) => (v && v.size < 30e6) || this.$t('pdf-size'),
      ];
    },
    connectionsRules() {
      return [
        (v) => !!v || this.$t('connections.required'),
      ];
    },
    typeRules() {
      return [
        (v) => (!!v || v === 0) || this.$t('types.required'),
      ];
    },
    relationIdRules() {
      return [
        (v) => (!v || /^[0-9]+(,[0-9]+)*$/.test(v)) || this.$t('relationId.comma'),
      ];
    },
  },
};
