import Vue from 'vue';
import VueRouter from 'vue-router';
import login from '@/views/login.vue';
import dashboard from '@/views/dashboard.vue';
import store from '@/store';
import middlewarePipeline from '@/router/middleware/middlewarePipeline';
import guards from '@/router/middleware/guards';
import adminView from '@/views/admin/admin.vue';
import deliveryNotes from '@/views/clients/deliveryNotes.vue';
import documents from '@/views/clients/documents.vue';
import usersAdmin from '@/views/admin/users.vue';
import config from '@/views/admin/config.vue';
import invoicesVendor from '@/views/vendors/invoicesVendors.vue';
import invoices from '@/views/clients/invoices.vue';
import certificates from '@/components/clients/certificates.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: login,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard,
    children: [
      {
        path: 'admin',
        name: 'admin',
        component: adminView,
        meta: {
          middleware: [
            guards.auth,
          ],
        },
      },
      {
        path: 'users-admin',
        name: 'usersAdmin',
        component: usersAdmin,
        meta: {
          middleware: [
            guards.auth,
            guards.admin,
          ],
        },
      },
      {
        path: 'config-admin',
        name: 'config',
        component: config,
        meta: {
          middleware: [
            guards.auth,
            guards.admin,
          ],
        },
      },
      {
        path: 'invoices',
        name: 'invoices',
        component: invoices,
        meta: {
          middleware: [
            guards.auth,
            guards.client,
          ],
        },
      },
      {
        path: 'delivery-notes',
        name: 'delivery-notes',
        component: deliveryNotes,
        meta: {
          middleware: [
            guards.auth,
            guards.client,
          ],
        },
      },
      {
        path: 'documents',
        name: 'documents',
        component: documents,
        meta: {
          middleware: [
            guards.auth,
            guards.documents,
          ],
        },
      },
      {
        path: 'certificates',
        name: 'certificates',
        component: certificates,
        meta: {
          middleware: [
            guards.auth,
            guards.client,
          ],
        },
      },
      {
        path: 'invoices-vendor',
        name: 'invoices-vendor',
        component: invoicesVendor,
        meta: {
          middleware: [
            guards.auth,
            guards.vendor,
          ],
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const { middleware } = to.meta;
  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1), // Due to many middleware
  });
});
export default router;
