<template>
  <invoices/>
</template>

<script>
import invoices from '@/components/clients/invoices.vue';

export default {
  components: {
    invoices,
  },
};
</script>
